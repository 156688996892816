import React from "react"
import test1 from "../images/Teri4.jpg"
import test2 from "../images/Teri3.jpg"
import Fade from "react-reveal/Fade"
import { Link } from "gatsby"
import branch from "../images/branch.svg"

function Testimonials(props) {
  const testimonials = [
    {
      name: "Miss Sweet",
      testimonial: `Lorem ipsum dolor sit amet, consectetur adipiscing elit. Fusce sed
        metus placerat, facilisis augue non, semper nunc. Orci varius
        natoque penatibus et magnis dis parturient montes, nascetur
        ridiculus mus. Sed lacinia est enim, sit amet congue mi venenatis
        et. Mauris consequat quam quis ex laoreet dapibus.`,
      image: test2,
    },
  ]
  const testimonial =
    testimonials[Math.floor(Math.random() * Math.floor(testimonials.length))]
  return (
    <div class="main-testimonials-container">
      {/* <div className="testimonial-title-container">
        <Fade bottom>
          <div>
            <h1>
              <Link to="/testimonials">Testimonials</Link>
            </h1>
            <img className="branches-right branches" src={branch} />
            <img className="branches-left branches" src={branch} />
          </div>
        </Fade>
      </div> */}

      <div
        className="testimonials-container"
        style={{
          backgroundImage: `url(${props.image})`,
          backgroundPosition: testimonial.backgroundPosition,
        }}
      >
        <Fade right>
          <div>
            <div class="testimonials-content-container">
              <h3 className="testimonial-title">{props.title}</h3>
              <p className="testimonial-content">{props.content}</p>
            </div>
          </div>
        </Fade>
      </div>
    </div>
  )
}

export default Testimonials
