import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import Reasons from "../components/reasons"
import teri2 from "../images/Teri2.jpg"
import Testimonials from "../components/testimonials"
import Fade from "react-reveal/Fade"

const AboutPage = props => {
  const {
    data: { markdownRemark },
  } = props
  return (
    <Layout>
      <SEO title="About" />
      <main className="main-container">
        <div className="title-container">
          <h1 className="page-title">{markdownRemark.frontmatter.title}</h1>
        </div>
        <div className="main-content-container">
          <div className="about-image">
            <img src={markdownRemark.frontmatter.contentImage} />
          </div>
          <div dangerouslySetInnerHTML={{ __html: markdownRemark.html }}></div>
        </div>
      </main>
      <Fade bottom>
        <Testimonials
          title={markdownRemark.frontmatter.testimonial.title}
          content={markdownRemark.frontmatter.testimonial.content}
          image={markdownRemark.frontmatter.testimonial.image}
        />
      </Fade>
    </Layout>
  )
}

export const pageQuery = graphql`
  query AboutPageTemplate {
    markdownRemark(frontmatter: { templateKey: { eq: "About" } }) {
      frontmatter {
        title
        templateKey
        contentImage
        testimonial {
          content
          image
          title
        }
      }
      html
    }
  }
`

export default AboutPage
